import { store } from '@/store/store';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { EvolucionMediciones } from '@/shared/dtos/DashboardsDtos/Paciente/EvolucionMediciones';
import { Comparacion_mediciones } from '@/shared/dtos/DashboardsDtos/Entrevista/Comparacion_mediciones';

@Module({
    namespaced: true,
    name: 'DashEvolucionPacienteModule',
    store,
    dynamic: true
})
class DashEvolucionPacienteModule extends VuexModule {
    public DashEvolucion: EvolucionMediciones = new EvolucionMediciones();
    public DashAntesDespues: Comparacion_mediciones = new Comparacion_mediciones();

    @Action({ commit: 'onGetDashPaciEvolucionPacientes' })
    public async getDashPaciEvolucionPacientes(id_paciente: number) {
        return await ssmHttpService.get(API.DashPaciEvolucionPacientes + '/' + id_paciente, null, false);
    }

    @Mutation
    public onGetDashPaciEvolucionPacientes(res: EvolucionMediciones) {
        this.DashEvolucion = res;
    }

    @Action({ commit: 'onGetDashAntesDespues' })
    public async getDashAntesDespues(paciente: number) {
        return await ssmHttpService.get(API.DashPaciEvolucionPacienteAntesDespues + '/' + paciente, null, false);
    }

    @Mutation
    public onGetDashAntesDespues(res: Comparacion_mediciones) {
        this.DashAntesDespues = res;
    }
}
export default getModule(DashEvolucionPacienteModule);