























































import { Component, Vue } from "vue-property-decorator";
import DashEvolucionPacienteModule from "@/store/modules/Dashboards/Pacientes/DashEvolucionPaciente-module";
import dash_campos_personalizadoModule from "@/store/modules/Dashboards/Campos_personalizados/dash_campos_personalizado-module";
import { UtilsString } from "@/utils/utils-string";
import { UtilsEncript } from "@/utils/utils-encript";

@Component({
  components: {
    DashComparacionMedicionesPaciente: () =>
      import("./DashComparacionMedicionesPaciente.vue"),
    LineMouths: () => import("@/components/Charts/LineMouths.vue"),
    PacienteFichaOpciones: () =>
      import("@/views/pacientes/paciente-ficha-opciones.vue"),
    ChartLine: () => import("@/components/Charts/ChartLine.vue"),
  },
})
export default class DashPaciente extends Vue {
  public id_paciente: number = 0;
  public created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id)) {
      this.id_paciente = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id)
      );
      DashEvolucionPacienteModule.getDashPaciEvolucionPacientes(
        this.id_paciente
      );
      dash_campos_personalizadoModule.getDashMedicionesCampos(this.id_paciente);
    }
  }
  public get source() {
    return DashEvolucionPacienteModule.DashEvolucion;
  }
  public get source_mediciones_personalizadas() {
    return dash_campos_personalizadoModule.dashboad;
  }
}
